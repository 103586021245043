import React from 'react';
import styles from './UserExperience.module.scss';
import { RichText } from 'prismic-reactjs';
import { array, object } from 'prop-types';

const UserExperience = ({ primary, items, setBackground }) => {
  const { title, subtitle, background_image } = primary;

  if (background_image) {
    setBackground(background_image);
  }
  return (
    <div className={styles.newpageHero}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
          <RichText render={subtitle.richText} />
          <div className={styles.iconslist}>
            {items?.map((item) => {
              return (
                <div className={styles.icon} key={item.icon}>
                  <img
                    src={item?.usericon?.url}
                    alt="partner logo"
                    loading="eager"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

UserExperience.propTypes = {
  primary: object,
  items: array,
  setBackground: object,
};

export default UserExperience;
