import React, { useState } from 'react';
import PropTypes, { string } from 'prop-types';
import style from './NewFront.module.scss';
import 'swiper/swiper.scss';
import BannerData from './components/BannerData';
import UserExperience from './components/UserExperience';
import GlobalTemp from './components/GlobalTemp';
import PrivacyMent from './components/PrivacyMent/PrivacyMent';
import NewRelatedBlog from './components/NewRelatedBlog/NewRelatedBlog';
import BreadcrumbsSemanticMarkup from '../../components/BreadcrumbsMarkup/BreadcrumbsMarkup';
import FaqSemanticMarkup from '../../components/FaqSemanticMarkup/FaqSemanticMarkup';
import { RichText } from 'prismic-reactjs';

const FrontPage = ({ current: body, canonical, metatitle, blogData }) => {
  const questions = body.filter((item) => item.slice_type === 'questions');
  const contentQuestions = body.filter((item) => item.slice_type === 'content');
  const questionsSliceFaqLists = questions.map((element) => element.items);
  const contentSliceFaqLists = contentQuestions.map((element) => element.items);
  const questionsSliceFaqList = questionsSliceFaqLists.flat();
  const contentSliceFaqList = contentSliceFaqLists.flat();
  const generalFaqList = [...questionsSliceFaqList, ...contentSliceFaqList];
  const [visibleItems, setVisibleItems] = useState(3);
  const [background, setBackground] = useState({});
  const handleClickLoadMore = () => {
    setVisibleItems(visibleItems + 3);
  };

  const herosection = body.map((section, index) => {
    switch (section.slice_type) {
      case 'userexperience':
        return (
          <UserExperience
            {...section}
            key={`${section.slice_type}${index}`}
            setBackground={setBackground}
          />
        );
      case 'bannerdata':
        return (
          <BannerData {...section} key={`${section.slice_type}${index}`} />
        );
    }
  });

  return (
    <div className={style.FrontPage}>
      <div className={style.parenthero}>
        <div className={style.background}>
          <img
            src={background?.url}
            alt={background?.alt || 'background'}
            loading="eager"
          />
        </div>
        {herosection}
      </div>
      <div>
        {body.map((section, index) => {
          switch (section.slice_type) {
            case 'globaltemplate':
              return (
                <GlobalTemp
                  {...section}
                  key={`${section.slice_type}${index}`}
                />
              );
            case 'privacymanagement':
              return (
                <div className={style.globalcontainer}>
                  <PrivacyMent
                    {...section}
                    key={`${section.slice_type}${index}`}
                  />
                </div>
              );
            case 'relatedblog':
              return (
                <div className={style.blogContainer}>
                  <div className={style.blogbackground}>
                    <img
                      src={background?.url}
                      alt={background?.alt || 'background'}
                    />
                  </div>
                  <div className={style.title}>
                    <RichText render={section?.primary?.title?.richText} />
                  </div>
                  <div className={style.list}>
                    {blogData?.slice(0, visibleItems)?.map((item) => {
                      return <NewRelatedBlog {...item} key={item.node.uid} />;
                    })}
                  </div>
                  <div className={style.buttonWrapper}>
                    {blogData?.length > 3 && visibleItems < 6 && (
                      <button
                        className={style.viewArticleButton}
                        onClick={handleClickLoadMore}
                      >
                        {section?.primary?.buttontext?.richText[0]?.text}
                      </button>
                    )}
                  </div>
                </div>
              );
            // default:
            //   throw new Error(`Unknown section type: ${section.slice_type}`);
          }
        })}
      </div>
      <FaqSemanticMarkup questions={generalFaqList} />
      <BreadcrumbsSemanticMarkup
        pageTitle={metatitle?.text}
        pageUrl={canonical?.text}
      />
    </div>
  );
};

FrontPage.propTypes = {
  current: PropTypes.array.isRequired,
  pageUid: PropTypes.string,
  canonical: PropTypes.object.isRequired,
  metatitle: PropTypes.object.isRequired,
  lang: PropTypes.string.isRequired,
  blogData: PropTypes.array,
  language: string,
};

export default FrontPage;
