import React, { useContext } from 'react';
import styles from './PrivacyMent.module.scss';
import { RichText } from 'prismic-reactjs';
import { Link } from 'gatsby';
import { langPath } from '@helpers';
import LangContext from '@contexts';
import { array, object } from 'prop-types';
import Image from '../../../../components/Image/Image';

const PrivacyMent = ({ primary, items }) => {
  const { title, description, buttonlink, buttontext } = primary;

  const currentLang = useContext(LangContext);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <RichText render={title.richText} />
      </div>
      <div className={styles.descr}>
        <RichText render={description.richText} />
      </div>
      <div className={styles.terms}>
        {items?.map(({ img, pagename }, index) => {
          const link = `${langPath(currentLang)}/${RichText.asText(
            pagename.richText
          )}`;
          return (
            <Link to={link} key={index}>
              <div className={styles.termsItem}>
                <Image image={img} />
              </div>
            </Link>
          );
        })}
      </div>
      <div className={styles.buttonWrapper}>
        <a className={styles.button} href={`${buttonlink.url}`}>
          <RichText render={buttontext.richText} />
        </a>
      </div>
    </div>
  );
};

PrivacyMent.propTypes = {
  primary: object,
  items: array,
};

export default PrivacyMent;
